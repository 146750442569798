import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { mainWhite, mainGreen, screen } from "../common/variables";
import Spinner from "../../images/svg/spinner-weather.svg";
import SunnyIcon from "../../images/svg/sunny-icon.svg";
import StormIcon from "../../images/svg/storm-icon.svg";
import RainyIcon from "../../images/svg/rainy-icon.svg";
import CloudyIcon from "../../images/svg/cloudy-icon.svg";
import SnowIcon from "../../images/svg/snow-icon.svg";
import SmokeIcon from "../../images/svg/smoke-icon.svg";
import TornadoIcon from "../../images/svg/tornado-icon.svg";
import FogIcon from "../../images/svg/fog-icon.svg";

const Wrapper = styled.div`
  color: ${mainGreen};
  position: relative;
  display: flex;
  align-items: center;
  background: ${mainWhite};
  padding-left: 60px;
  margin-right: 20px;
  padding: 9px 18px 9px 40px;
  border-radius: 30px;
  @media ${screen.xsmall} {
    padding: 12px 20px 12px 45px;
  }

  #icon-weather {
    width: 100%;
    max-width: 21px;
    position: absolute;
    top: calc(50% - 1px);
    left: 13px;
    transform: translate(0, -50%);
    @media ${screen.xsmall} {
      max-width: 22px;
      left: 16px;
    }
    @media ${screen.medium} {
      max-width: 25px;
    }
  }

  #spinner-weather {
    width: 100%;
    max-width: 50px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
  }

  #pipe-divider {
    font-size: 1.4rem;
    margin-right: 5px;
    @media ${screen.xsmall} {
      margin-right: 7px;
    }
    @media ${screen.medium} {
      margin-right: 5px;
    }
  }

  #location-weather {
    font-size: 0.97rem;
    font-weight: 500;
    line-height: 1.5;
    @media ${screen.xsmall} {
      font-size: 1rem;
    }
    @media ${screen.medium} {
      font-size: 1.15rem;
    }
  }
`;

const Weather = ({ className, lat, lon }) => {
  const [weather, setWeather] = useState(null);

  // get weather info from api
  useEffect(() => {
    fetch(
      `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lon}&units=metric&APPID=${process.env.GATSBY_OPENWEATHER_API_KEY}`
    )
      .then(res => res.json())
      .then(data => setWeather(data.weather[0].main))
      .catch(err => setWeather("error"));
  }, []);

  return (
    <Wrapper className={className}>
      <Spinner
        id="spinner-weather"
        style={{ display: weather ? "none" : "block" }}
      />
      {weather === `Clouds` && <CloudyIcon id="icon-weather" />}
      {weather === `Clear` && <SunnyIcon id="icon-weather" />}
      {weather === `Rain` && <RainyIcon id="icon-weather" />}
      {weather === `Thunderstorm` && <StormIcon id="icon-weather" />}
      {weather === `Drizzle` && <RainyIcon id="icon-weather" />}
      {weather === `Snow` && <SnowIcon id="icon-weather" />}
      {weather === `Mist` && <CloudyIcon id="icon-weather" />}
      {weather === `Smoke` && <SmokeIcon id="icon-weather" />}
      {weather === `Haze` && <CloudyIcon id="icon-weather" />}
      {weather === `Dust` && <SmokeIcon id="icon-weather" />}
      {weather === `Fog` && <FogIcon id="icon-weather" />}
      {weather === `Sand` && <SmokeIcon id="icon-weather" />}
      {weather === `Ash` && <CloudyIcon id="icon-weather" />}
      {weather === `Squall` && <CloudyIcon id="icon-weather" />}
      {weather === `Tornado` && <TornadoIcon id="icon-weather" />}

      {/* // fallback if something went wrong from the weather api */}
      {weather === `error` && <FogIcon id="icon-weather" />}
      <span id="pipe-divider">|</span>
      <span id="location-weather">SYDNEY, NSW</span>
    </Wrapper>
  );
};

export default Weather;
