import React from "react";
import { Link, graphql } from "gatsby";
import SEO from "../components/seo";
import styled from "styled-components";
import { ScrollLocky } from "react-scroll-locky";
import {
  mainWhite,
  mainGreen,
  mainPink,
  innerWidth,
  screen,
} from "../components/common/variables";
import Weather from "../components/weather";
import BackgroundImage from "gatsby-background-image";
import { slugify, timeConvert, timeFormat } from "../components/common/helpers";

const Wrapper = styled.div`
  min-height: 50vh;
  background: ${mainWhite};

  .home {
    height: ${(props) =>
      props.warning
        ? `${props.browserHeight - 48}px`
        : !props.warning
        ? `${props.browserHeight}px`
        : `100vh`};
    padding: 100px 30px 0 30px;
    position: relative;
    min-height: 50vh;
    @media ${screen.xsmall} {
      padding: 200px 70px 0 70px;
      height: ${(props) =>
        props.warning ? `calc(100vh - 48px)` : `calc(100vh)`};
    }
    @media ${screen.large} {
      padding: 200px 0 0 0;
    }

    &__info {
      max-width: ${innerWidth};
      margin: 0 auto;

      .heading {
        color: ${mainGreen};
        font-size: 1rem;
        font-weight: 600;
        @media ${screen.xsmall} {
          font-size: 1.3rem;
        }
        @media ${screen.medium} {
          font-size: 1.6rem;
        }
      }

      .ride-title {
        color: ${mainWhite};
        font-size: 1.87rem;
        text-transform: uppercase;
        font-weight: 500;
        margin-top: 6px;
        @media ${screen.xsmall} {
          font-size: 3.6rem;
          margin-top: 26px;
        }
        @media ${screen.medium} {
          font-size: 4.5rem;
        }
      }

      .ride-measure {
        color: ${mainWhite};
        font-size: 1rem;
        font-weight: 500;
        display: block;
        @media ${screen.xsmall} {
          font-size: 1.7rem;
          margin-top: -5px;
        }
        @media ${screen.medium} {
          font-size: 2.1rem;
        }
      }

      .ride-weather {
        display: flex;
        margin-top: 16px;
        @media ${screen.xsmall} {
          margin-top: 32px;
        }

        &__navigate {
          background: ${mainPink};
          color: ${mainWhite};
          font-size: 1.15rem;
          padding: 12px 30px;
          border-radius: 30px;
          line-height: 1.5;
          display: none;
          transition: background 0.19s ease-out;
          @media ${screen.xsmall} {
            font-size: 1rem;
            display: block;
          }
          @media ${screen.medium} {
            font-size: 1.15rem;
          }
          @media ${screen.withCursor} {
            &:hover {
              background: ${mainGreen};
            }
          }

          &--sm {
            display: block;
            position: absolute;
            bottom: 50px;
            width: calc(100% - 70px);
            left: 50%;
            transform: translateX(-50%);
            text-align: center;
            @media ${screen.xsmall} {
              display: none;
            }
          }
        }
      }
    }
    &--lg {
      display: none;
      @media ${screen.small} {
        display: block;
      }
    }

    &--sm {
      @media ${screen.small} {
        display: none;
      }
    }
  }
`;

const IndexPage = (props) => {
  // height prop passed from layout component
  const { browserHeight, browserWidth, isNavOpen, warning } = props;

  const isDesktop = browserWidth > 1240;

  // main home page content from prismic query
  const {
    // intro,
    featured_image_portrait,
    title_tag,
    meta_description,
  } = props.data.content.data;

  // latest ride content from prismic query
  const latestRideTitle = props.data.latestRide.edges.length
    ? props.data.latestRide.edges[0].node.data.title.text
    : props.data.latestRideFallback.edges[0].node.data.title.text;
  const latestRideDistance = props.data.latestRide.edges.length
    ? props.data.latestRide.edges[0].node.data.distance
    : props.data.latestRideFallback.edges[0].node.data.distance;
  const latestRideDuration = props.data.latestRide.edges.length
    ? props.data.latestRide.edges[0].node.data.approximate_time
    : props.data.latestRideFallback.edges[0].node.data.approximate_time;
  const latestRideFromLocation = props.data.latestRide.edges.length
    ? props.data.latestRide.edges[0].node.data.from
    : props.data.latestRideFallback.edges[0].node.data.from;
  const latestRideImageLandscape = props.data.latestRide.edges.length
    ? props.data.latestRide.edges[0].node.data.featured_image_landscape.fluid
    : props.data.latestRideFallback.edges[0].node.data.featured_image_landscape
        .fluid;
  const latestRideImagePortrait = props.data.latestRide.edges.length
    ? props.data.latestRide.edges[0].node.data.featured_image_portrait.fluid
    : props.data.latestRideFallback.edges[0].node.data.featured_image_portrait
        .fluid;

  return (
    <>
      <SEO
        title={title_tag}
        description={meta_description}
        image={featured_image_portrait.url}
      />
      <ScrollLocky enabled={!isNavOpen && !isDesktop} isolation={false}>
        <Wrapper browserHeight={browserHeight} warning={warning}>
          <BackgroundImage
            className="home home--lg"
            fluid={latestRideImageLandscape}
          >
            <div className="home__info">
              <h2 className="heading">LATEST RIDE</h2>
              <h1 className="ride-title">{latestRideTitle}</h1>
              <span className="ride-measure">
                {latestRideDistance}
                {latestRideDistance >= 2 ? "kms" : "km"} |{" "}
                {timeConvert(latestRideDuration)}
                {timeFormat(latestRideDuration)}
              </span>
              <div className="ride-weather">
                <Weather
                  className="ride-weather__weather"
                  lat={latestRideFromLocation.latitude}
                  lon={latestRideFromLocation.longitude}
                />

                <Link
                  to={`/rides/${slugify(latestRideTitle)}/`}
                  className="ride-weather__navigate"
                  state={{ prevPath: "/" }}
                  title={`${latestRideTitle} ride`}
                >
                  TELL ME MORE!
                </Link>
              </div>
            </div>
          </BackgroundImage>
          <BackgroundImage
            className="home home--sm"
            fluid={latestRideImagePortrait}
          >
            <div className="home__info">
              <h2 className="heading">LATEST RIDE</h2>
              <h1 className="ride-title">{latestRideTitle}</h1>
              <span className="ride-measure">
                {latestRideDistance}kms | {timeConvert(latestRideDuration)}
                {timeFormat(latestRideDuration)}
              </span>
              <div className="ride-weather">
                <Weather
                  className="ride-weather__weather"
                  lat={latestRideFromLocation.latitude}
                  lon={latestRideFromLocation.longitude}
                />

                <Link
                  to={`/rides/${slugify(latestRideTitle)}/`}
                  className="ride-weather__navigate"
                  title={`${latestRideTitle} ride`}
                >
                  TELL ME MORE!
                </Link>
                <Link
                  to={`/rides/${slugify(latestRideTitle)}/`}
                  className="ride-weather__navigate ride-weather__navigate--sm"
                  state={{ prevPath: "/" }}
                  title={`${latestRideTitle} ride`}
                >
                  TELL ME MORE!
                </Link>
              </div>
            </div>
          </BackgroundImage>
        </Wrapper>
      </ScrollLocky>
    </>
  );
};

export default IndexPage;

export const dataQuery = graphql`
  {
    content: prismicHomePage {
      data {
        intro {
          text
        }
        featured_image_landscape {
          url
          fluid {
            ...GatsbyPrismicImageFluid
          }
        }
        featured_image_portrait {
          url
          fluid {
            ...GatsbyPrismicImageFluid
          }
        }
        title_tag
        meta_description
      }
    }
    latestRide: allPrismicRide(
      filter: { data: { is_latest: { eq: true } } }
      limit: 1
      sort: { fields: first_publication_date, order: DESC }
    ) {
      edges {
        node {
          data {
            title {
              text
            }
            distance
            approximate_time
            from {
              latitude
              longitude
            }
            featured_image_landscape {
              fluid {
                ...GatsbyPrismicImageFluid
              }
            }
            featured_image_portrait {
              fluid {
                ...GatsbyPrismicImageFluid
              }
            }
          }
        }
      }
    }
    latestRideFallback: allPrismicRide(
      sort: { fields: first_publication_date, order: DESC }
      limit: 1
    ) {
      edges {
        node {
          data {
            title {
              text
            }
            distance
            approximate_time
            from {
              latitude
              longitude
            }
            featured_image_landscape {
              fluid {
                ...GatsbyPrismicImageFluid
              }
            }
            featured_image_portrait {
              fluid {
                ...GatsbyPrismicImageFluid
              }
            }
          }
        }
      }
    }
  }
`;
